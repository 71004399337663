import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const KpiReport = ({ report, setReport }) => {
  const [managersData, setManagersData] = useState([]);
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const { id } = useParams();

  useEffect(() => {
    const fetchKpis = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/kpis/reports/all?reported_by=${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const kpiData = response.data.data[0];
        setManagersData([
          {
            id: kpiData.id,
            name: kpiData.kpi?.name || "Unnamed KPI",
            due_date: kpiData.due_date,
            targets:
              kpiData.target_reports?.map((report) => ({
                id: report.id,
                name: report.name || `Target ${report.id}`,
                notes: report.notes || "No notes available",
                challenges: report.challenges || "No challenges available",
                description: report.description || "No challenges available",
                total_expected: report.total_expected || 0,
                actual_completed: report.actual_completed || 0,
              })) || [],
            description: kpiData.kpi?.description || "No description available",
            status: kpiData.status || "No status available",
            start_date: kpiData.start_date,
            created_at: kpiData.created_at,
            percentageCompleted: kpiData.percentage_completed || 0,
            actual_completed: kpiData.actual_completed || 0,
            notes: kpiData.notes || "No notes available",
            challenges: kpiData.challenges || "No challenges available",
          },
        ]);
      } catch (error) {
        console.error("Error fetching KPIs:", error);
      }
    };

    fetchKpis();
  }, [id, apiUrl, token]);

  return (
    <>
      <div
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
        onClick={() => setReport(false)}
      >
        <div
          className="relative bg-white rounded-lg shadow-xl w-[90%] max-w-4xl animate-fadeIn p-8 space-y-6"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            onClick={() => setReport(false)}
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="text-center space-y-4">
            <h3 className="text-3xl font-semibold text-gray-800">
              {managersData.length > 0 ? managersData[0].name : "Loading..."}
            </h3>
            <span
              className={`inline-block px-4 py-1 text-sm font-semibold rounded-full ${
                managersData[0]?.status === "SUBMITTED"
                  ? "bg-green-200 text-green-800"
                  : managersData[0]?.status === "PENDING"
                  ? "bg-yellow-200 text-yellow-800"
                  : "bg-red-200 text-red-800"
              }`}
            >
              {managersData[0]?.status || "No Status"}
            </span>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="font-medium text-gray-600">Description:</span>
              <span className="text-gray-800">
                {managersData[0]?.description}
              </span>
            </div>

            <div className="flex justify-between items-center">
              <span className="font-medium text-gray-600">Completion:</span>
              <span className="text-gray-800">
                {managersData[0]?.percentageCompleted.toFixed(2)}%
              </span>
            </div>

            {managersData[0]?.targets?.length > 0 && (
              <div className="mt-6 space-y-4">
                <h4 className="text-xl font-semibold text-gray-700">Targets</h4>
                {managersData[0]?.targets.map((target) => (
                  <div
                    key={target.id}
                    className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200"
                  >
                    <div className="flex justify-between items-center">
                      <h5 className="text-md font-bold text-blue-600 mb-2">
                        {target.name}
                      </h5>
                    </div>
                    <p className="text-sm text-gray-700">
                      <strong>Description:</strong> {target.description}
                    </p>
                    <p className="text-sm text-gray-700">
                      <strong>Notes:</strong> {target.notes}
                    </p>
                    <p className="text-sm text-gray-700">
                      <strong>Challenges:</strong> {target.challenges}
                    </p>

                    <div className="flex space-x-2 mt-4">
                      <span className="text-gray-800">
                        Expected: {target.total_expected}
                      </span>
                      <span className="text-gray-800">
                        Completed: {target.actual_completed}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default KpiReport;
