import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faClipboardCheck,
  faEdit,
  faTimes,
  faTrashCan,
  faCheckCircle,
  faTimesCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import { CSSTransition } from "react-transition-group";
import KpiReport from "./KpiReport";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);

const QuickStatsManager = ({ quickStats }) => {
  const [loading, setLoading] = useState(false);
  const [managersData, setManagersData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [showNotification, setshowNotification] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const [report, setReport] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const navigate = useNavigate();
  const [del, setDel] = useState(false);
  const [chartData, setChartData] = useState({
    labels: ["SUBMITTED", "PENDING", "UNDONE"],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: [
          "rgb(102, 193, 220)", // Completed
          "rgb(255, 205, 86)", // In Progress
          "rgb(234, 56, 36)", // Undone
        ],
      },
    ],
  });
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const kpiOptions = [
    { value: "HOURS DELIVERED", label: "HOURS DELIVERED" },
    { value: "PRIVATE CLIENTS", label: "PRIVATE CLIENTS" },
    { value: "LA CLIENTS", label: "LA CLIENTS" },
    { value: "CARE PLANS COMPLETED", label: "CARE PLANS COMPLETED" },
    { value: "CARE PLANS REVIEW", label: "CARE PLANS REVIEW" },
    { value: "STAFF TRAINING", label: "STAFF TRAINING" },
    { value: "MEDICATION AUDITS", label: "MEDICATION AUDITS" },
    { value: "COMPLAINTS", label: "COMPLAINTS" },
    { value: "QUALITY MONITORING", label: "QUALITY MONITORING" },
  ];
  const [formInitialValues, setFormInitialValues] = useState({
    name: "",
    description: "",
    start_date: "",
    due_date: "",
    total_expected: "",
    assignees: [],
    period: "WEEKLY",
  });

  const periodOptions = [
    { value: "WEEKLY", label: "Weekly" },
    { value: "MONTHLY", label: "Monthly" },
  ];

  const formatDateForInput = (date) => {
    const pad = (n) => n.toString().padStart(2, "0");
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}`;
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("KPI title is required"),
    description: Yup.string().required("Description is required"),
    start_date: Yup.date().required("Start date is required"),
    due_date: Yup.date()
      .required("Due date is required")
      .min(Yup.ref("start_date"), "Due date must be after start date"),
    total_expected: Yup.number()
      .typeError("Total expected must be a number")
      .min(0, "Total expected cannot be negative")
      .required("Total expected is required"),
    assignees: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required(),
        label: Yup.string().required(),
      })
    ),
    period: Yup.string()
      .oneOf(["WEEKLY", "MONTHLY"], "Invalid period")
      .required("Period is required"),
  });

  const totalPages = Math.ceil(managersData.length / itemsPerPage);
  const paginatedData = managersData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleUpdate = (id) => {
    setUpdate(true);
    navigate("/kpi_management", { state: { highlightId: id } });
  };

  useEffect(() => {
    const fetchKpis = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/admin/kpis/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const kpiData = response.data.data.filter((kpi) => kpi.user_id === id);
        const today = new Date();
        const startOfToday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );

        const kpiWithPercentages = kpiData.map((kpi) => {
          const totalTargets = kpi.targets.length;

          const completedTargets = kpi.targets.filter((target) => {
            if (target.is_inverted) {
              return target.total_expected === 0;
            }
            return target.total_expected > 0;
          }).length;

          const percentageCompleted = totalTargets
            ? (completedTargets / totalTargets) * 100
            : 0;

          return {
            ...kpi,
            percentage_completed: percentageCompleted,
          };
        });

        const groupedManagers = kpiWithPercentages.map((kpi) => {
          const status =
            kpi.percentage_completed === 100
              ? "SUBMITTED"
              : moment().isAfter(moment(kpi.due_date)) &&
                new Date() >=
                  new Date(
                    new Date(kpi.due_date).getFullYear(),
                    new Date(kpi.due_date).getMonth(),
                    new Date(kpi.due_date).getDate() + 1
                  )
              ? "Undone"
              : "PENDING";

          return {
            id: kpi.user_id,
            kpiId: kpi.id,
            managerName: kpi.assignee_name || "N/A",
            assignee: kpi.assignee,
            assigner: kpi.assigner,
            current_report_id: kpi.has_period_current_report,
            description: kpi.description,
            title: kpi.name || "N/A",
            reported: kpi.has_current_period_report,
            status,
            startDate: kpi.start_date || "N/A",
            dueDate: kpi.due_date || "N/A",
            percentageCompleted: kpi.percentage_completed || 0,
            period: kpi.period,
            targets: kpi.targets || [],
          };
        });

        setManagersData(groupedManagers);

        const totalTasks = kpiData.length;

        const completedTasks = kpiWithPercentages.filter(
          (kpi) => kpi.percentage_completed === 100
        ).length;

        const undoneTasks = kpiWithPercentages.filter((kpi) => {
          const dueDate = new Date(kpi.due_date);

          const dayAfterDueDate = new Date(
            dueDate.getFullYear(),
            dueDate.getMonth(),
            dueDate.getDate() + 1
          );

          return (
            kpi.percentage_completed < 100 && dayAfterDueDate <= startOfToday
          );
        }).length;

        const inProgressTasks = totalTasks - completedTasks - undoneTasks;

        setChartData({
          labels: ["SUBMITTED", "PENDING", "UNDONE"],
          datasets: [
            {
              data: [completedTasks, inProgressTasks, undoneTasks],
              backgroundColor: [
                "rgb(102, 193, 220)", // Completed
                "rgb(255, 205, 86)", // In Progress
                "rgb(234, 56, 36)", // Undone
              ],
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching KPIs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchKpis();
  }, [apiUrl, token, id]);

  const openModal = (manager) => {
    setSelectedManager(manager);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedManager(null);
    setIsModalOpen(false);
  };

  const handleSubmit = async (values) => {
    setshowNotification(null);
    setIsSubmitting(true);

    try {
      const payload = {
        ...values,
        start_date: moment(values.start_date).format("YYYY-MM-DD"),
        due_date: moment(values.due_date).format("YYYY-MM-DD"),
        assignees: values.assignees.map((assignee) => assignee.value),
      };

      const response = await axios.patch(
        `${apiUrl}/api/v1/tracker/targets/${selectedManager.id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setUpdate(false);
      window.location.reload();
    } catch (error) {
      console.error("Error updating target:", error);
      setErrorNotification("Error updating target. Please try again.");
      setTimeout(() => setErrorNotification(false), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    setDel(true);

    try {
      await axios.delete(
        `${apiUrl}/api/v1/tracker/admin/kpis/${selectedManager.kpiId}/`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      window.location.reload();
      closeModal();
      setDel(false);
    } catch (error) {
      console.error("Error deleting task:", error);
      setErrorNotification("Failed to delete the KPI.");
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
      setDel(false);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "bg-green-100 text-green-700";
      case "Undone":
        return "bg-red-100 text-red-700";
      case "PENDING":
        return "bg-yellow-100 text-yellow-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("user"))?.access;
        const response = await axios.get(`${apiUrl}/api/v1/auth/users/`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const filteredManagers = response.data.data.filter(
          (user) => user.user_type === "MANAGER"
        );

        const options = filteredManagers.map((manager) => ({
          value: manager.id,
          label: `${manager.first_name} ${manager.last_name}`,
        }));

        setAssigneeOptions(options);
      } catch (error) {
        console.error("Error fetching assignees:", error);
        setNotificationMessage("Failed to load assignees");
        setTimeout(() => {
          setNotificationMessage(false);
        }, 3000);
      }
    };

    fetchAssignees();
  }, [apiUrl]);

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  const myClassNames = {
    enter: "opacity-0",
    enterActive: "opacity-100 transition-opacity duration-500 ease-in-out",
    exitActive: "opacity-0 transition-opacity duration-500 ease-in-out",
  };

  useEffect(() => {
    if (selectedManager || update) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [selectedManager, update]);

  return (
    <>
      <div className="bg-white mb-4 shadow-md p-4 rounded-md mt-[24px]">
        <h2 className="font-bold mb-4">Quick Stats</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Total KPIs</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.totalKpis}
            </span>
          </div>

          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Completed</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.completed}
            </span>
          </div>

          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs">Undone</span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.undone}
            </span>
          </div>

          <div className="flex bg-gray-100 p-2 border-dashed border rounded-md flex-col items-center">
            <span className="text-gray-500 text-xs text-center">
              Completion Rate
            </span>
            <span className="text-gray-900 text-2xl font-bold">
              {quickStats.completionRate}%
            </span>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 mb-3 flex items-center justify-center flex-col rounded-md shadow-sm">
        <h2 className="font-bold text-sm self-start mb-6">
          KPI Completion Breakdown
        </h2>
        <div style={{ maxWidth: "350px" }}>
          {chartData &&
          chartData.datasets &&
          chartData.datasets[0].data.some((val) => val > 0) ? (
            <Doughnut
              data={chartData}
              options={{ maintainAspectRatio: true }}
            />
          ) : (
            <p className="text-center text-gray-500">
              No data available for the chart
            </p>
          )}
        </div>
      </div>

      <div className="mb-24">
        {loading ? (
          <div className="col-span-full text-center text-gray-500">
            Loading KPIs...
          </div>
        ) : managersData.length > 0 ? (
          <>
            {/* Weekly KPIs Section */}
            <div className="font-bold mb-4 bg-white shadow-md rounded-lg p-4">
              Weekly KPIs
            </div>
            <div className="grid grid-cols-1 -mt-3 md:grid-cols-1 lg:grid-cols-2 gap-6">
              {managersData.filter((kpi) => kpi.period === "WEEKLY").length >
              0 ? (
                managersData
                  .filter((kpi) => kpi.period === "WEEKLY")
                  .map((manager) => (
                    <div
                      key={manager.id}
                      onClick={() => openModal(manager)}
                      className="cursor-pointer bg-gradient-to-r from-white to-gray-100 shadow-lg rounded-lg p-6 hover:shadow-xl transition-all"
                    >
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-xl font-semibold text-black">
                          {manager.title}
                        </h3>
                        <span
                          className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusClass(
                            manager.status || "In Progress"
                          )}`}
                        >
                          {manager.status || "In Progress"}
                        </span>
                      </div>
                      <div className="flex items-center gap-4 mb-4">
                        <FontAwesomeIcon
                          icon={faUser}
                          className="text-gray-500"
                        />
                        <span className="text-lg text-gray-800 font-medium">
                          {manager.managerName}
                        </span>
                      </div>
                      <p className="text-slate-600 text-sm">
                        <strong>Description: </strong>
                        {manager.description}
                      </p>
                      <div className="flex items-center mt-4 gap-2">
                        <FontAwesomeIcon
                          icon={
                            manager.status === "SUBMITTED"
                              ? faCheckCircle
                              : faTimesCircle
                          }
                          style={{
                            color:
                              manager.status === "SUBMITTED" ? "green" : "red",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            color:
                              manager.status === "SUBMITTED" ? "green" : "red",
                          }}
                        >
                          {manager.status === "SUBMITTED"
                            ? "Reported"
                            : "Not Reported"}
                        </span>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="col-span-full text-center text-gray-500">
                  No KPIs for this period.
                </div>
              )}
            </div>

            {/* Monthly KPIs Section */}
            <div className="font-bold mt-8 mb-4 bg-white shadow-md rounded-lg p-4">
              Monthly KPIs
            </div>
            <div className="grid grid-cols-1 -mt-3 md:grid-cols-1 lg:grid-cols-2 gap-6">
              {managersData.filter((kpi) => kpi.period === "MONTHLY").length >
              0 ? (
                managersData
                  .filter((kpi) => kpi.period === "MONTHLY")
                  .map((manager) => (
                    <div
                      key={manager.id}
                      onClick={() => openModal(manager)}
                      className="cursor-pointer bg-gradient-to-r from-white to-gray-100 shadow-lg rounded-lg p-6 hover:shadow-xl transition-all"
                    >
                      <div className="flex items-center justify-between mb-4">
                        <h3 className="text-xl font-semibold text-black">
                          {manager.title}
                        </h3>
                        <span
                          className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusClass(
                            manager.status || "In Progress"
                          )}`}
                        >
                          {manager.status || "In Progress"}
                        </span>
                      </div>
                      <div className="flex items-center gap-4 mb-4">
                        <FontAwesomeIcon
                          icon={faUser}
                          className="text-gray-500"
                        />
                        <span className="text-lg text-gray-800 font-medium">
                          {manager.managerName}
                        </span>
                      </div>
                      <p className="text-slate-600 text-sm">
                        <strong>Description: </strong>
                        {manager.description}
                      </p>
                      <div className="flex items-center mt-4 gap-2">
                        <FontAwesomeIcon
                          icon={
                            manager.status === "SUBMITTED"
                              ? faCheckCircle
                              : faTimesCircle
                          }
                          style={{
                            color:
                              manager.status === "SUBMITTED" ? "green" : "red",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            color:
                              manager.status === "SUBMITTED" ? "green" : "red",
                          }}
                        >
                          {manager.status === "SUBMITTED"
                            ? "Reported"
                            : "Not Reported"}
                        </span>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="col-span-full text-center text-gray-500">
                  No KPIs for this period.
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="col-span-full text-center text-gray-500">
            There are currently no active KPIs.
          </div>
        )}
      </div>

      <CSSTransition
        in={selectedManager}
        timeout={300}
        classNames={myClassNames}
        unmountOnExit
      >
        <>
          {selectedManager && (
            <div
              className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
              onClick={closeModal}
            >
              <div
                className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg max-h-[700px] overflow-y-scroll animate-fadeIn p-8"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={closeModal}
                  className="flex justify-self-end mb-4"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="text-center">
                  <h3 className="text-3xl font-bold text-gray-800 mb-4">
                    {selectedManager.title}
                  </h3>
                  <span
                    className={`px-3 py-1 rounded-full text-xs font-medium ${getStatusClass(
                      selectedManager.status || "In Progress"
                    )}`}
                  >
                    {selectedManager.status || "In Progress"}
                  </span>
                </div>

                <div className="mt-6 space-y-4">
                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">
                      Assignee Name:
                    </span>
                    <span className="text-gray-800">
                      {selectedManager.managerName}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">Period:</span>
                    <span className="text-gray-800">
                      {selectedManager.period}
                    </span>
                  </div>

                  {selectedManager.targets &&
                    selectedManager.targets.length > 0 && (
                      <div className="mt-6">
                        <h4 className="text-lg font-semibold text-gray-700 mb-3">
                          Targets
                        </h4>
                        <div className="space-y-4">
                          {selectedManager.targets.map((target, index) => (
                            <div
                              key={target.id || index}
                              className="border rounded-lg p-4 shadow-sm bg-gray-50 hover:bg-gray-100 transition duration-200"
                            >
                              <h5 className="text-md font-bold text-blue-600 mb-2">
                                {target.name}
                              </h5>
                              <p className="text-sm text-gray-700">
                                {target.description}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">
                      Description:
                    </span>
                    <span className="text-gray-800">
                      {selectedManager.description}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="font-medium text-gray-600">
                      Completion:
                    </span>
                    <span className="text-gray-800">
                      {selectedManager.percentageCompleted}%
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-3 mt-6 items-center">
                  <button
                    onClick={() => handleUpdate(selectedManager.kpiId)}
                    disabled={selectedManager.percentageCompleted === 100}
                    className="bg-btn w-full flex items-center justify-center text-white disabled:opacity-50 px-4 py-2 rounded-full"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                    &nbsp; Update
                  </button>
                  <button
                    className="bg-danger w-full flex items-center justify-center text-white px-4 py-2 rounded-full"
                    onClick={handleDelete}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                    &nbsp; delete
                  </button>
                  <button
                    onClick={() => setReport(true)}
                    disabled={selectedManager.percentageCompleted <= 20}
                    className="bg-blue-500 col-span-2 w-full disabled:opacity-50 px-4 py-2 rounded-full text-white flex justify-center items-center gap-2"
                  >
                    <FontAwesomeIcon icon={faClipboardCheck} /> View Report
                  </button>
                </div>
              </div>
              <CSSTransition
                in={report}
                timeout={300}
                classNames={myClassNames}
                unmountOnExit
              >
                <KpiReport
                  report={report}
                  selectedManager={selectedManager}
                  setReport={setReport}
                />
              </CSSTransition>
            </div>
          )}
        </>
      </CSSTransition>

      {/* UPDATE FORM  */}
      <CSSTransition
        in={update}
        timeout={300}
        classNames={myClassNames}
        unmountOnExit
      >
        <>
          <div
            className="fixed inset-0 overflow-scroll z-50 flex items-center justify-center bg-black/70 backdrop-blur-sm"
            onClick={() => setUpdate(false)}
          >
            <div
              className="relative bg-white rounded-lg shadow-lg w-[90%] max-w-lg animate-fadeIn p-8"
              onClick={(e) => e.stopPropagation()}
            >
              <FontAwesomeIcon
                onClick={() => setUpdate(false)}
                icon={faTimes}
                className="float-right"
              />
              <h2 className="text-lg font-bold mb-4">Update Target</h2>
              <Formik
                initialValues={formInitialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="mb-4">
                      <label className="block text-sm font-medium mb-1">
                        KPI Title
                      </label>
                      <CreatableSelect
                        options={kpiOptions}
                        onChange={(option) =>
                          setFieldValue("name", option?.value || option)
                        }
                        value={
                          kpiOptions.find(
                            (option) => option.value === values.name
                          ) || {
                            value: values.name,
                            label: values.name,
                          }
                        }
                        placeholder="Select or type a KPI title"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium">
                        Description
                      </label>
                      <Field
                        name="description"
                        placeholder="Target description"
                        className="w-full border rounded-md p-2"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium">
                        Start Date
                      </label>
                      <Field
                        type="date"
                        name="start_date"
                        className="w-full border rounded-md p-2"
                      />
                      <ErrorMessage
                        name="start_date"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium">
                        Due Date
                      </label>
                      <Field
                        type="date"
                        name="due_date"
                        className="w-full border rounded-md p-2"
                      />
                      <ErrorMessage
                        name="due_date"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium">
                        Total Expected
                      </label>
                      <Field
                        type="number"
                        name="total_expected"
                        placeholder="0"
                        className="w-full border rounded-md p-2"
                      />
                      <ErrorMessage
                        name="total_expected"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium">
                        Assignees
                      </label>
                      <Select
                        options={assigneeOptions}
                        isMulti
                        onChange={(options) =>
                          setFieldValue("assignees", options)
                        }
                        value={values.assignees}
                      />
                    </div>

                    <div className="flex items-center mb-4">
                      <input
                        type="checkbox"
                        id="select-all"
                        className="mr-2"
                        onChange={(e) => {
                          const allSelected = e.target.checked;
                          setFieldValue(
                            "assignees",
                            allSelected ? assigneeOptions : []
                          );
                        }}
                        checked={
                          values.assignees.length === assigneeOptions.length &&
                          assigneeOptions.length > 0
                        }
                      />
                      <label htmlFor="select-all" className="text-sm">
                        All Managers
                      </label>
                    </div>

                    <div className="mb-4">
                      <label className="block text-sm font-medium">
                        Period
                      </label>
                      <Select
                        options={periodOptions}
                        onChange={(option) =>
                          setFieldValue("period", option?.value)
                        }
                        value={periodOptions.find(
                          (opt) => opt.value === values.period
                        )}
                      />
                      <ErrorMessage
                        name="period"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <button
                      type="submit"
                      className="bg-btn w-full text-white rounded-md p-2"
                    >
                      {isSubmitting ? "Updating..." : "Update"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </>
      </CSSTransition>

      <CSSTransition
        in={showNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <p className="font-light">{showNotification}</p>
          </span>
        </div>
      </CSSTransition>

      <CSSTransition
        in={errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{errorNotification}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default QuickStatsManager;
