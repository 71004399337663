import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Register Chart.js components
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
);

const KpiCompletionBreakdown = () => {
  const [loading, setLoading] = useState(false);
  const [managersData, setManagersData] = useState([]);
  const [chartData, setChartData] = useState({
    labels: ["SUBMITTED", "PENDING", "UNDONE"],
    datasets: [
      {
        data: [0, 0, 0],
        backgroundColor: [
          "rgb(102, 193, 220)", // Completed
          "rgb(255, 205, 86)", // In Progress
          "rgb(234, 56, 36)", // Undone
        ],
      },
    ],
  });
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const navigate = useNavigate();

  const fetchKpis = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/v1/tracker/admin/kpis/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const kpiData = response.data.data;
      const today = new Date();

      const kpiWithPercentages = kpiData.map((kpi) => {
        const totalTargets = kpi.targets.length;

        const completedTargets = kpiData.filter(
          (kpi) => kpi.percentage_completed === 100
        ).length;

        const percentageCompleted = totalTargets
          ? (completedTargets / totalTargets) * 100
          : 0;

        return {
          ...kpi,
          percentage_completed: percentageCompleted, // Update percentage
        };
      });

      // Group tasks by manager
      const groupedTasks = kpiWithPercentages.reduce((acc, kpi) => {
        const status =
          kpi.percentage_completed === 100
            ? "SUBMITTED"
            : new Date(kpi.due_date) < today
            ? "Undone"
            : "PENDING";

        const managerId = kpi.user_id;
        const managerName = kpi.assignee_name || "N/A";

        if (!acc[managerId]) {
          acc[managerId] = {
            managerId,
            managerName,
            tasks: [],
          };
        }

        acc[managerId].tasks.push({
          id: kpi.user_id,
          title: kpi.name || "N/A",
          period: kpi.period || "N/A",
          percentageCompleted: kpi.percentage_completed || 0,
          status,
        });

        return acc;
      }, {});

      setManagersData(Object.values(groupedTasks));

      const totalTasks = kpiWithPercentages.length;
      const completedTasks = kpiWithPercentages.filter(
        (kpi) => kpi.percentage_completed === 100
      ).length;
      const undoneTasks = kpiWithPercentages.filter(
        (kpi) =>
          new Date(kpi.due_date) < today && kpi.percentage_completed !== 100
      ).length;
      const inProgressTasks = totalTasks - completedTasks - undoneTasks;

      setChartData({
        labels: ["SUBMITTED", "PENDING", "UNDONE"],
        datasets: [
          {
            data: [completedTasks, inProgressTasks, undoneTasks],
            backgroundColor: [
              "rgb(102, 193, 220)", // Completed
              "rgb(255, 205, 86)", // In Progress
              "rgb(234, 56, 36)", // Undone
            ],
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching KPIs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKpis();
  }, []);

  const getStatusClass = (status) => {
    switch (status) {
      case "SUBMITTED":
        return "bg-green-100 text-green-700";
      case "Undone":
        return "bg-red-100 text-red-700";
      case "PENDING":
        return "bg-yellow-100 text-yellow-700";
      default:
        return "bg-gray-100 text-gray-700";
    }
  };

  return (
    <>
      <div className="bg-white flex items-center justify-center flex-col p-4 mb-3 rounded-md shadow-sm">
        <h2 className="font-bold text-sm self-start mb-6">
          KPI Completion Breakdown
        </h2>
        <div style={{ maxWidth: "350px" }}>
          {chartData &&
          chartData.datasets &&
          chartData.datasets[0].data.some((val) => val > 0) ? (
            <Doughnut
              data={chartData}
              options={{ maintainAspectRatio: true }}
            />
          ) : (
            <p className="text-center text-gray-500">
              No data available for the chart.
            </p>
          )}
        </div>
      </div>

      <div className="mb-24">
        <div className="font-bold mb-4 bg-white shadow-md rounded-lg p-4">
          Active KPIs
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6">
          {loading ? (
            <div className="col-span-full text-center text-gray-500">
              Loading KPIs...
            </div>
          ) : managersData.length > 0 ? (
            managersData.map((manager) => (
              <div
                key={manager.managerId}
                className="bg-white shadow-lg rounded-lg p-6 mb-6"
              >
                <h2 className="text-xl font-semibold mb-4">
                  {manager.managerName}
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                  {manager.tasks.map((task) => (
                    <div
                      key={task.id}
                      onClick={() => navigate(`/managers/${task.id}`)}
                      className="cursor-pointer bg-gray-100 rounded-md p-4 shadow-md hover:shadow-lg transition-all"
                    >
                      <span className="flex justify-between items-center">
                        <h3 className="text-lg font-bold mb-2">{task.title}</h3>
                        <div className="flex justify-between items-center mb-2">
                          <span
                            className={`px-3 py-1 rounded-full text-xs whitespace-nowrap font-medium ${getStatusClass(
                              task.status
                            )}`}
                          >
                            {task.status}
                          </span>
                        </div>
                      </span>
                      <p className="text-sm">
                        <strong>Period: </strong>
                        {task.period}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center text-gray-500">
              There are currently no active KPIs.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default KpiCompletionBreakdown;
