import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimes,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { useParams } from "react-router-dom";

const KpiReportForm = ({ setReport, selectedManager }) => {
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;
  const userId = JSON.parse(localStorage.getItem("user"))?.user_id;
  const [errorNotification, setErrorNotification] = useState(false);
  const [successNotif, setSuccessNotif] = useState(false);
  const { id } = useParams();
  const totalExpectedRef = useRef([]);
  const reportIdsRef = useRef(null);
  const targetRef = useRef(null);

  const [initialValues, setInitialValues] = useState({
    id,
    target_reports: [],
  });

  useEffect(() => {
    const fetchKpis = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/kpis/reports/all?reported_by=${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const kpiData = response.data.data[0];
        const targetNames =
          kpiData.target_reports?.map((report) => report.name) || [];

        const targetExpected =
          kpiData.target_reports?.map((report) => report.total_expected) || [];

        const reportId = kpiData.id;

        totalExpectedRef.current = targetExpected;
        targetRef.current = targetNames;
        reportIdsRef.current = reportId;
        setInitialValues((prevValues) => ({
          target_reports:
            kpiData.target_reports?.map((report) => ({
              id: report.id,
              notes: report.notes || "",
              challenges: report.challenges || "",
              actual_completed: report.actual_completed || 0,
            })) || [],
        }));
      } catch (error) {
        console.error("Error fetching KPIs:", error);
        setErrorNotification("Failed to fetch KPI data. Please try again.");
      }
    };

    fetchKpis();
  }, [apiUrl, token, userId]);

  const validationSchema = Yup.object({
    target_reports: Yup.array().of(
      Yup.object().shape({
        notes: Yup.string(),
        challenges: Yup.string(),
        actual_completed: Yup.number().min(
          0,
          "Actual completed must be at least 0"
        ),
      })
    ),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.put(
        `${apiUrl}/api/v1/tracker/kpis/reports/${reportIdsRef.current}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setSuccessNotif("KPI report submitted successfully!");
      setTimeout(() => {
        setSuccessNotif(false);
      }, 2000);
      setInitialValues(values);
    } catch (err) {
      console.error("API Error:", err.response?.data || err.message);
      setErrorNotification(
        err.response?.data?.message ||
          err.response?.data?.error ||
          "An error occurred. Please try again."
      );
      setTimeout(() => {
        setErrorNotification(false);
      }, 3000);
    } finally {
      setSubmitting(false);
    }
  };

  const styles = {
    enter: "transform translate-x-full opacity-0",
    enterActive:
      "transform -translate-x-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-x-full opacity-0 transition-all duration-500 ease-in-out",
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-lg p-8">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form className="space-y-4">
              <FieldArray name="target_reports">
                {({ form }) => (
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">
                      Target Reports
                    </h2>

                    <div className="grid gap-3 lg:grid-cols-2">
                      {form.values.target_reports.map((report, index) => (
                        <div
                          key={index}
                          className="space-y-4 p-4 border border-gray-200 rounded-md shadow-sm"
                        >
                          <h3 className="text-lg font-bold text-blue-600 mb-4">
                            {targetRef.current[index] ||
                              "Target Name Not Found"}
                          </h3>
                          <h3 className="font-bold underline text-sm text-gray-700 mb-4">
                            <strong>Expected Completion: </strong>
                            {totalExpectedRef.current[index] || 0}
                          </h3>
                          <div>
                            <label
                              htmlFor={`target_reports.${index}.actual_completed`}
                              className="block text-sm font-medium text-gray-600"
                            >
                              Actual Completed
                            </label>
                            <Field
                              name={`target_reports.${index}.actual_completed`}
                              type="number"
                              className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                              onChange={(e) => {
                                form.setFieldValue(
                                  `target_reports.${index}.actual_completed`,
                                  e.target.value
                                );
                              }}
                            />
                            <ErrorMessage
                              name={`target_reports.${index}.actual_completed`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`target_reports.${index}.notes`}
                              className="block text-sm font-medium text-gray-600"
                            >
                              Notes
                            </label>
                            <Field
                              name={`target_reports.${index}.notes`}
                              as="textarea"
                              className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                            />
                            <ErrorMessage
                              name={`target_reports.${index}.notes`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor={`target_reports.${index}.challenges`}
                              className="block text-sm font-medium text-gray-600"
                            >
                              Challenges
                            </label>
                            <Field
                              name={`target_reports.${index}.challenges`}
                              as="textarea"
                              className="w-full p-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                            />
                            <ErrorMessage
                              name={`target_reports.${index}.challenges`}
                              component="div"
                              className="text-red-500 text-sm mt-1"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </FieldArray>

              <button
                type="submit"
                className="w-full bg-btn text-white py-2 rounded-md font-medium hover:bg-orange-600 focus:outline-none focus:ring focus:ring-blue-200 disabled:bg-gray-300"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>

      <CSSTransition
        in={!!errorNotification}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-danger rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-danger" icon={faTimes} />
            <p className="font-light">{errorNotification}</p>
          </span>
        </div>
      </CSSTransition>

      <CSSTransition
        in={successNotif}
        classNames={styles}
        timeout={500}
        unmountOnExit
      >
        <div className="p-4 shadow-xl z-50 bg-white fixed right-3 top-10 border-s-2 border-success rounded-md flex items-center gap-4">
          <span className="flex items-center gap-4">
            <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
            <p className="font-light">{successNotif}</p>
          </span>
        </div>
      </CSSTransition>
    </>
  );
};

export default KpiReportForm;
