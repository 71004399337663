import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { faArrowLeft, faBullseye } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import KpiCompletionBreakdown from "../Components/KpiCompletionBreakdown";
import AddKpi from "../Components/AddKpi";
import PeriodContextSelector from "../Components/PeriodContextSelector";
import QuickStats from "../Components/QuickStats";

const Kpi = () => {
  const [target, setTarget] = useState(false);
  const [quickStats, setQuickStats] = useState({
    totalKpis: 0,
    completed: 0,
    undone: 0,
    completionRate: 0,
  });
  const navigate = useNavigate();
  const apiUrl = import.meta.env.VITE_API_URL;
  const token = JSON.parse(localStorage.getItem("user"))?.access;

  const styles = {
    enter: "transform translate-y-full opacity-0",
    enterActive:
      "transform -translate-y-0 opacity-100 transition-all duration-500 ease-in-out",
    exitActive:
      "transform translate-y-full opacity-0 transition-all duration-500 ease-in-out",
  };

  useEffect(() => {
    const fetchQuickStats = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/tracker/admin/kpis/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const kpiData = response.data.data;

        const managerKpiData = kpiData.filter(
          (kpi) => kpi?.assignee && kpi.assignee !== ""
        );

        const today = new Date();
        const startOfToday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate()
        );

        const totalTargets = managerKpiData.reduce(
          (acc, kpi) => acc + (kpi.targets?.length || 0),
          0
        );

        const completedTargets = managerKpiData.reduce((acc, kpi) => {
          return (
            acc +
            kpi.targets?.filter((target) => target.total_expected === 0).length
          );
        }, 0);

        const undoneTasks = managerKpiData.reduce((acc, kpi) => {
          const overdueTargets = kpi.targets?.filter((target) => {
            const dueDate = new Date(kpi.due_date);
            const dayAfterDueDate = new Date(
              dueDate.getFullYear(),
              dueDate.getMonth(),
              dueDate.getDate() + 1
            );

            return (
              kpi.percentage_completed < 100 && dayAfterDueDate <= startOfToday
            );
          }).length;

          return acc + (overdueTargets || 0);
        }, 0);

        const totalExpected = managerKpiData.length;
        const totalCompleted = managerKpiData.filter(
          (kpi) => kpi.percentage_completed === 100
        ).length;

        const completionRate = totalExpected
          ? ((totalCompleted / totalExpected) * 100).toFixed(2)
          : "N/A";

        setQuickStats({
          totalKpis: totalExpected,
          completed: totalCompleted,
          undone: undoneTasks,
          completionRate: completionRate,
        });
      } catch (error) {
        console.error("Error fetching quick stats:", error);
      }
    };

    fetchQuickStats();
  }, [apiUrl, token]);

  return (
    <>
      <div className="bg-[#F8F9FC] md:ml-[350px] p-4">
        <div className="bg-white flex items-center justify-between p-4 mb-3 rounded-md shadow-sm">
          <span className="flex items-center gap-2">
            <FontAwesomeIcon onClick={() => navigate(-1)} icon={faArrowLeft} />
            <h2 className="font-bold text-sm">KPI Dashboard</h2>
          </span>

          <Link to="/new_kpi">
            <button className="bg-btn rounded-md px-4 py-2 text-white">
              New KPI &nbsp;
              <FontAwesomeIcon icon={faBullseye} />
            </button>
          </Link>
        </div>

        <PeriodContextSelector />

        <div className="bg-white p-4 mb-3 rounded-md shadow-sm">
          <div className="flex items-center justify-between">
            <h2 className="font-bold text-sm">Quick Stats</h2>
            <div className="relative w-[160px]">
              <button
                className="bg-white border w-full border-gray-300 rounded-md shadow-sm px-2 py-2 focus:outline-none"
                type="button"
              >
                All Managers
              </button>
            </div>
          </div>

          <QuickStats quickStats={quickStats} />
        </div>

        <KpiCompletionBreakdown />
        {/* <AddKpi styles={styles} target={target} setTarget={setTarget} /> */}
      </div>
    </>
  );
};

export default Kpi;
